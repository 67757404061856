var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "f-form-item",
    {
      attrs: {
        label: _vm.label,
        required: _vm.required,
        "show-label": _vm.showLabel,
        "no-space": _vm.$attrs["no-space"]
      }
    },
    [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.innerValue,
            expression: "innerValue"
          }
        ],
        ref: "textarea",
        staticClass: "form-control",
        class: _vm.inputClass,
        attrs: {
          id: _vm.id,
          rows: _vm.$attrs.rows,
          cols: _vm.$attrs.cols,
          disabled: _vm.disabled,
          required: _vm.required
        },
        domProps: {
          value: _vm.innerValue
        },
        on: {
          keyup: _vm.onKeyUp,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.innerValue = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _vm.helpText
        ? _c("f-form-help-text", {
            attrs: {
              text: _vm.helpText,
              icon: _vm.helpIcon
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("below-input")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }