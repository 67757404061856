












import { Component, Prop, Vue } from 'vue-property-decorator';
import vue2Dropzone from 'vue2-dropzone';
import translate from '@/util/translate';

@Component({
    components: {
        vueDropzone: vue2Dropzone
    }
})
export default class Dropzone extends Vue {

    @Prop({ required: true, type: String })
    id: string;

    @Prop({ required: false, type: String, default: '/'})
    processUrl: string;

    @Prop({ required: false, type: Number, default: 15 })
    maxFiles: Number;

    @Prop({ required: false, type: String, default: 'Upload files' })
    label: String;

    @Prop({ required: false, type: Boolean, default: false })
    disableUpload: boolean;

    dropzoneOptions = {
        url: this.processUrl,
        thumbnailWidth: 150,
        maxFilesize: 100,
        dictDefaultMessage: `<i class='fa fa-cloud-upload'></i> ${ translate(this.label) }`,
        autoProcessQueue: !this.disableUpload,
        maxFiles: this.maxFiles,
        addRemoveLinks: this.disableUpload
    };

    vdropzoneMaxFilesExceeded(file): void {
        this.$refs['dropzone'].removeFile(file);
    };

    vdropzoneFileAdded(data: object): void {
        this.$emit('file-added', data);
    }

    vdropzoneComplete(data: object): void {
        EventBus.$emit('vdropzone-complete', {
            'id': this.id,
            'data': data,
        });
        this.$emit('file-uploaded');
    }

    clear(): void {
        this.$refs['dropzone'].removeAllFiles();
    }

    mounted(): void {
        this.$on('clear', this.clear);
    }
}
