var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("validation-provider", {
    attrs: {
      tag: "div",
      vid: _vm.vid,
      name: _vm.translate(_vm.$attrs.label),
      rules: _vm.rules
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(_ref) {
            var errors = _ref.errors
            return _c(
              "f-form-item",
              {
                attrs: {
                  "error-messages": errors,
                  required: _vm.$attrs.required,
                  "show-label": false
                }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "form-check-label"
                  },
                  [_vm._t("default", [_vm._v(_vm._s(_vm.$attrs.label))])],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("dropzone", {
                      ref: "dropzone",
                      attrs: {
                        id: _vm.vid,
                        "max-files": 1,
                        "disable-upload": ""
                      },
                      on: {
                        "file-added": _vm.emitFile
                      }
                    })
                  ],
                  1
                )
              ]
            )
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }