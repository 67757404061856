import FServerForm, { FormChildTriggerEvent, ValidationChangedEvent } from '@/components/form/f-server-form.vue';
import { omit } from 'lodash';
import PlaceholderOptions from '@/components/form/placeholders';
import { UIChangeTrigger } from '@/components/form/UIChangeTrigger';
import createObserver from '@/util/observer';

type FormItemChoice<A = Record<string, string>> = { label: string, value: InputValueTypes, attr: A };
type OptionFilterFunc = (option: FormItemChoice) => boolean;

type FormChild = {
    attr: Record<string, string>|string[];
    name: string;
    label: string;
    value: string;
    id: string;
    lazyLoaded?: boolean;
    lazyLoadTrigger?: string;
    editLinkRoute?: string
    editLinkRouteAttributes?: Record<string, any>
    listEntityLink?: ListEntityLink|null
    updateEntityLink?: UpdateEntityLink|null
    childType: FormChildType;
    help: string|'';
    children?: Record<string, FormChild>;
    disabled: boolean;
    required: boolean;
}

enum FormChildType {
    TEXT = 'text',
    TIME = 'time',
    TEXT_AREA = 'textarea',
    COLOR = 'color',
    NUMBER = 'number',
    SELECT = 'select',
    CHECKBOX = 'checkbox',
    DATE = 'date',
    DATE_TIME = 'datetime',
    FILE = 'file',
    COLLECTION = 'collection'
}

type FormChoiceChild<T = Record<string, any>> = FormChild & {
    choices: FormItemChoice[];
    data: Record<string, any>|null
    prototype: ServerForm;
    multiple: boolean;
    expanded: true;
    entityTypeAttr: T
}

type FormDateChild = FormChild & {
    format: string;
}

type FormCollectionChild<T extends Record<string, any> = Record<string, any>> = FormChild & {
    collectionAttr: T;
    children: Record<string, FormChild>;
}

type OnSubmitSuccessHandler<T extends Record<string, unknown>> = (data: T) => (any|Promise<void>)

type FormChildren = FormChild|FormChoiceChild|FormDateChild|FormCollectionChild;

type InputValueTypes = string|number|string[]|boolean;
type ServerFormChildren<K extends string = string> = Record<K, FormChildren>;
type ServerFormValues<K extends string = string> = Record<K, InputValueTypes>;
type ServerFormValueObjects<K extends string = string> = Record<K, FormItemChoice|undefined>;

const emptyServerFormModel = Object.freeze({ values: {}, childChoices: {}, valueObjects: {} }) as ServerFormModel<any>;


type ServerForm<K extends string = string, AD extends Record<string, unknown> = Record<string, unknown>> = {
    name: string;
    constraints: Record<string, string[]>;
    additionalData: AD;
    children: ServerFormChildren<K>;
}

type ServerFormModel<K extends string = string> = {
    values: ServerFormValues<K>;
    childChoices: Record<string, Array<FormItemChoice>>;
    valueObjects: ServerFormValueObjects<K>;
    additionalData: Record<string, any>
}

const isChoiceType = (child: FormChildren): child is FormChoiceChild => {
    if (child == undefined) return false;
    return 'multiple' in child;
};

const isCollectionType = (child: FormChildren): child is FormCollectionChild => {
    if (child == undefined) return false;
    return 'collectionAttr' in child;
};

const getInitialValueForFormChild = (child: FormChildren) : InputValueTypes => {
    if (isChoiceType(child)) {
        return [];
    }

    return '';
};

interface FServerFormContext {
    initialized: () =>  boolean,
    initialElementIsFocused: () =>  boolean,
    setInitialElementIsFocused: (focused: boolean) =>  void,
    getFormModel: <T extends string = string> () =>  ServerFormModel<T>,
    lazyLoadedChoices: (child: FormChoiceChild) => void,
    triggerFormChildChange: (field: string|FormChildren, trigger: UIChangeTrigger) => void,
    placeholdersAreDisabled: () => boolean
    getChildPlaceholderMap: () => Partial<Record<FormChildType, PlaceholderOptions>>|null
    onInitialized: (callback: () => void) => void,
    onFormFieldValidationChange(field: string, callback: (event: ValidationChangedEvent) => void)
    onFormFieldTrigger<T extends string = string>(field: T|FormChildren, callback: (event: FormChildTriggerEvent) => void),
    reloadForm: () => Promise<void>,
    childFinishedInitializing: (childName: string) => void
    registerChild: (childName: string) => void
}


const getChildValueDisplay = (child: FormChildren, formModel: ServerFormModel): string => {
    const valueObject = formModel.valueObjects[child.name];

    if (!valueObject) {
        const val = formModel.values[child.name];

        if (Array.isArray(val)) {
            if (val.length === 0) return '';

            return val.join(', ');
        }

        if (val === true || val === false) return '';

        return val.toString();
    }

    if (Array.isArray(valueObject)) {
        return valueObject.map(vo => vo.label).join(', ');
    }

    return valueObject.label;
};

const getSelectedObjectFromChoices = (value: any, options: FormItemChoice[], valueField = 'value') => {
    if (value === '') {
        return null;
    }

    if (Array.isArray(value)) {
        return value.map(v => getSelectedObjectFromChoices(v, options));
    }

    if(typeof options === 'object' && !Array.isArray(options) && options !== null) {
        options = Object.entries(options);
    }

    for (const option of options) {
        if (option[valueField] === value) {
            return option;
        }
    }

    return null;
};


class FormReference {
    constructor(private id: string) {
    }

    getId(): string {
        return this.id + '-Form';
    }

    getForm(): FServerForm|null {
        return ServerFormRegistry.forms[this.getId()] ?? null;
    }

    onRegister(callback: (form: FServerForm) => void): void {
        ServerFormRegistry.onRegister(this.getId(), (event: FormInitializedEvent) => callback(event.form));
    }
}

type FormInitializedEvent = { form: FServerForm };

class ServerFormRegistry {
    public static forms: Record<string, FServerForm> = {};
    private static registerListenerMap = createObserver<FormInitializedEvent>();

    static register(form: FServerForm): void {
        this.forms[form.idString] = form;

        this.registerListenerMap.dispatchByKey(form.idString, { form });
    }

    static onRegister(id: string, listener: (event: FormInitializedEvent) => void) {
        this.registerListenerMap.observeForKey(id, listener);
    }

    static unregister(form: FServerForm): void {
        this.forms = omit(this.forms, form.idString);
        this.registerListenerMap.stopObservingForKey(form.idString);
    }
}

interface FormElementInterface {
    getInputElement: () => HTMLInputElement|null
}



export { emptyServerFormModel, getInitialValueForFormChild, isCollectionType, isChoiceType, FormReference, ServerFormRegistry, FormChildType, getSelectedObjectFromChoices, getChildValueDisplay };
export type { ServerForm, ServerFormModel, FormElementInterface, FormCollectionChild, FormChild, FormChildren, FServerFormContext, OnSubmitSuccessHandler, ServerFormValueObjects, FormItemChoice, FormChoiceChild, OptionFilterFunc, InputValueTypes, ServerFormChildren, ServerFormValues };
