var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("vue-dropzone", {
        ref: "dropzone",
        attrs: {
          id: _vm.id,
          options: _vm.dropzoneOptions
        },
        on: {
          "vdropzone-max-files-exceeded": _vm.vdropzoneMaxFilesExceeded,
          "vdropzone-file-added": _vm.vdropzoneFileAdded,
          "vdropzone-complete": _vm.vdropzoneComplete
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }